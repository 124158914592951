.container {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(235, 238, 243, 0.867);
}

.progressbar{
  background-color: rgba(225, 225, 228, 0.867);
}

.article.card.p{
  align-items: center;
}

.nav{
  background-color: rgb(29, 28, 27);
}

.box-tol-gerada{
  background-color: black;
}

.box-tol-gerada-font{
  color: aliceblue;
}

.spinner{
  margin-left: auto;
  margin-right: auto;
  align-content:stretch ;
}