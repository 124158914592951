.numeroTicket{
    margin-left: auto;
    margin-right: auto;
    width: 9.5em
}

.GeraqrCode{
    margin-top: 13px;
    margin-left: auto;
    margin-right: auto;
    width: 6em
}